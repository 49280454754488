import React, {
  FC,
  MutableRefObject,
  RefCallback,
  useEffect,
  useRef
} from "react";
import { CircularProgress } from "@mui/material";
import {
  handleLoaderTransitionEnd,
  handleMouseMove,
  handlePlayClick,
  handlePosterTransitionEnd
} from "component-media-player/eventHandlers";
import { usePlayer } from "component-media-player/hooks";
import "component-media-player/styles/index.scss";
import { MediaPlayerProps } from "component-media-player/types";
import Controls from "../Controls";
import * as medias from "../medias";
import { Poster } from "../Poster";

const THUMBNAIL_BORDER_SIZE = 1;
const THUMBNAIL_WIDTH = `${220 + 2 * THUMBNAIL_BORDER_SIZE}px`;

export const MediaPlayer: FC<MediaPlayerProps> = props => {
  const {
    ControlsProps,
    controlsType,
    mediaDriver = "hls",
    mediaType = "video",
    poster
  } = props;
  const player = usePlayer(props);

  const mediaRef: MutableRefObject<HTMLMediaElement | null> =
    useRef<HTMLMediaElement>(null);

  const setMediaRef: RefCallback<HTMLMediaElement> = ref => {
    mediaRef.current = ref;
  };

  const renderLoader = () => (
    <div
      className="rh-mp__loader__wrapper"
      data-view-state={player.playerState.misc.loaderViewState}
      onTransitionEnd={handleLoaderTransitionEnd.bind(mediaRef, player, {})}
    >
      <CircularProgress className="rh-mp__loader" />
    </div>
  );

  const renderPoster = () => {
    if (!poster?.url) {
      return <></>;
    }

    return (
      <div
        className="rh-mp__poster__wrapper"
        data-view-state={player.playerState.misc.posterViewState}
        onTransitionEnd={handlePosterTransitionEnd.bind(mediaRef, player, {})}
      >
        <Poster
          className="rh-mp__poster"
          mediaRef={mediaRef}
          player={player}
          poster={poster}
        />
      </div>
    );
  };

  const renderMedia = () => {
    const Media = medias?.[mediaType]?.[mediaDriver];

    if (!Media) {
      return <></>;
    }

    return <Media ref={setMediaRef} player={player} />;
  };

  const {
    misc: { thumbnailOffset }
  } = player.playerState;

  const ref = useRef<HTMLDivElement>();

  const style = ref.current?.style;

  useEffect(() => {
    style?.setProperty("--bg-color", props.bgColor ?? "#000");
    style?.setProperty(
      "--poster-aspect-ratio-lg",
      props?.poster?.sizes?.lg?.aspectRatio ?? "16 / 9"
    );
    style?.setProperty(
      "--poster-aspect-ratio-md",
      props?.poster?.sizes?.md?.aspectRatio ?? "16 / 9"
    );
    style?.setProperty(
      "--poster-aspect-ratio-sm",
      props?.poster?.sizes?.sm?.aspectRatio ?? "16 / 9"
    );
    style?.setProperty(
      "--poster-aspect-ratio-xl",
      props?.poster?.sizes?.xl?.aspectRatio ?? "16 / 9"
    );
    style?.setProperty(
      "--poster-aspect-ratio-xs",
      props?.poster?.sizes?.xs?.aspectRatio ?? "16 / 9"
    );
    style?.setProperty(
      "--source-aspect-ratio",
      props.source?.aspectRatio ?? "16 / 9"
    );
    style?.setProperty("--thumbnail-offset", thumbnailOffset.toString());
    style?.setProperty("--thumbnail-width", THUMBNAIL_WIDTH);
  }, [props, style, thumbnailOffset]);

  const isHidingControls = ["hidden", "hiding"].includes(
    player.playerState.misc.controlsViewState
  );

  return (
    <div
      ref={ref}
      className={[
        "rh-mp",
        player.playerState.fullscreen && "rh-mp-fs",
        player.playerProps.controlsType !== "none" &&
          isHidingControls &&
          "rh-mp-controls-hidden",
        "rh-mp__root"
      ]
        .filter(Boolean)
        .join(" ")}
      onMouseMove={handleMouseMove.bind(mediaRef, player, {})}
      style={{
        visibility: mediaRef.current ? undefined : "hidden" // To prevent FOUC
      }}
    >
      <div
        className="rh-mp__media__wrapper"
        onClick={
          player.playerProps.controlsType === "none" &&
          handlePlayClick.bind(mediaRef, player, {})
        }
      >
        {renderLoader()}
        {renderPoster()}
        {renderMedia()}
      </div>

      {(controlsType === "custom" || controlsType === "simple") && (
        <Controls
          {...ControlsProps}
          className="rh-mp__controls-embedded"
          mediaRef={mediaRef}
          player={player}
        />
      )}
    </div>
  );
};

MediaPlayer.defaultProps = {
  autoplay: false,
  bgColor: "#000",
  controlsType: "none",
  mediaDriver: "hls",
  mediaType: "video",
  muted: true,
  showPosterSecondsDelay: 5,

  ControlsProps: {
    ...(Controls.defaultProps as any)
  }
};

export default MediaPlayer;
